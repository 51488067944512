import React from "react";
import CarPng from "../../Assets/rolls.png";

const About = () => {
  return (
    <div id="cars" className="dark:bg-dark bg-slate-100 sm:min-h-[600px] sm:grid sm:place-items-center duration-300">
      <div className="container">
        <div className="grid grid-cols-1 sm:grid-cols-2 place-items-center">
          <div data-aos="slide-right" data-aos-duration="1500" className="pt-5">
            <img
              src={CarPng}
              alt=""
              className="sm:scale-125 sm:-translate-x-11 max-h-[300px] drop-shadow-[2px_10px_6px_rgba(0,0,0,0.50)]"
            />
          </div>
          <div>
            <div className="space-y-5 sm:p-16 pb-6">
              <h1
                data-aos="fade-up"
                className="text-3xl lg:text-4xl font-bold font-Ubuntu"
              >
                About us
              </h1>
              <p data-aos="fade-up" className="leading-8 tracking-wide font-Ubuntu">
              LAST MINUTE DISPATCH  in partnership with Marina Global Transportation & Blessed Global Transportation, 
              is proud to offer unparalleled luxury travel experiences. Our commitment to excellence is reflected 
              in our team of highly skilled and professional drivers, setting us apart as the premier 
              limousine service in Georgia.
                
                {/* Our culture is quality luxury transportation services,
                 with commitment to meeting the individual needs of each
                  customer has propelled MARINA GLOBAL TRANSPORTATION as the #1 limousine company in GA,
                   Unique fleet of luxurious limousines, 
                   professional chauffeurs, red carpet VIP limo services etc. */}
                              </p>

              <p data-aos="fade-up" className="font-Ubuntu">
              Recognized for our dedication to quality and personalized service, 
              we cater to both corporate and executive travelers with a fleet of exquisite vehicles and top-tier chauffeurs. From VIP red carpet 
              services to seamless transportation across Atlanta and over 500 cities worldwide, 
              we guarantee an exceptional journey every time.
              </p>
              <p data-aos="fade-up font-Ubuntu" className="pb-10">
              Experience the difference with us—just one call is all it takes to begin your luxurious adventure. We look forward to serving you!
              </p>
              {/* <p data-aos="fade-up font-Ubuntu">
              With just one phone call, experience all the fun and excitement
              </p> */}
              <a href="https://customer.moovs.app/marina-global-transportation" data-aos="fade-up" className="bg-black text-white px-10 py-3 rounded-full font-Ubuntu">
              {/* <a href="https://book.mylimobiz.com/v4/blessedlimotrans" data-aos="fade-up" className="bg-black text-white px-10 py-3 rounded-full font-Ubuntu"> */}
                Book Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

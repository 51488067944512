import React, { useEffect, useState } from "react";
import carPng from "../../Assets/car.png";
import car from "../../Assets/ab.png";
import AOS from "aos";
import About from "../About/About";
import Services from "../Services/Services";
import CarList from "../CarList/CarList";
import Testimonial from "../Testimonial/Testimonial";
import AppStoreBanner from "../AppStoreBanner/AppStoreBanner";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Part from "../Paterners/Part";
import { Link } from "react-router-dom";
import vid from '../../Assets/666.mp4'
import Foot from "./Foot";
import { RiMailFill, RiPhoneFill, RiWhatsappFill } from "react-icons/ri";


const Home = ({ theme }) => {
  useEffect(() => {
    AOS.refresh();
  });
  

  return (
    <div>
      <div className="dark:bg-black dark:text-white duration-400 h-screen ">
      <div
  // style={{width: '100%'}}
  // className="lg:hidden w-[100%]  h-[130%] flex justify-center items-center object-cover absolute"
  className="w-[100%] h-[100%] flex justify-center items-center object-cover absolute lg:mb-20 mb-40"
  dangerouslySetInnerHTML={{
    __html: `<video  style="
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    object-fit: cover;
    // z-index: -1;" 
    autoplay loop muted playsinline>
    <source src=${vid} type="video/mp4" />
    Your browser does not support the video tag.
  </video>`
}}
/> 
        <div  className="text-center items-center justify-center w-[100%] h-[100%] flex">
        {/* <div className="container min-h-[620px] flex"> */}
          <div className="grid place-items-center">
          {/* <div className="grid grid-cols-1 sm:grid-cols-2 place-items-center"> */}
            {/* <div
              data-aos="zoom-in"
              data-aos-duration="1500"
              data-aos-once="false"
              className="order-1 sm:order-2"
            >
              <img
                src={theme === "dark" ? "https://lirp.cdn-website.com/a6e9c45a/dms3rep/multi/opt/DSC00120-640w.png" : car}
                // src={theme === "dark" ? carPng : car}
                alt=""
                className="sm:scale-125 relative -z-10 max-h-[600px] drop-shadow-[2px_20px_6px_rgba(0,0,0,0.50)] w-[90%]"
              />
            </div> */}
            <div className="space-y-5 order-2 sm:order-1 text-center">
            {/* <div className="space-y-5 order-2 sm:order-1 sm:pr-32 md:ml-20 text-center"> */}
            {/* <div className="flex items-center ">
        <div className="flex items-center">

              <a href="mailto:info@blessedlimos.com" target="_blank" className="mr-5">
                <RiMailFill className="text-2xl hover:text-primary ml-2 duration-300" /> 
              </a>
        </div>
              |

              <div className="flex items-center">
              <a href="https://wa.me/+14049579242" target="_blank" className="">
                <RiWhatsappFill className="text-2xl hover:text-primary text-green-600 duration-300" />
              </a>
              </div>
              |
              <div className="flex items-center">
              <a href="tel:(800) 556-1849"  target="_blank">
                <RiPhoneFill className="text-2xl hover:text-primary duration-300" />
              </a>
              </div>
            </div> */}
              <p
                data-aos="fade-up"
                className="text-primary text-4xl font-Ubuntu"
              >
                PREMIER CHOICE
              </p>
              <h1
                data-aos="fade-up"
                data-aos-delay="600"
                className="text-4xl lg:text-6xl text-white font-bold font-Ubuntu"
              >
                LAST MINUTE DISPATCH
              </h1>
              <p data-aos="fade-up" data-aos-delay="1000" className="text-white text-2xl px-10 font-Ubuntu">
                Get started with us to experience the difference
              </p>
              <Link to='https://customer.moovs.app/marina-global-transportation'>
              {/* <Link to='/Booking'> */}
              <button
                data-aos="fade-up"
                data-aos-delay="1500"
                onClick={() => {
                  AOS.refreshHard();
                }}
                className="rounded-md bg-primary hover:bg-primary/80 transition duration-500 py-2 m-5 px-6 text-white font-Ubuntu font-semibold"
                >

Book Now
</button>
                </Link>
            </div>
          </div>
        </div>
      </div>
      <About />
      <AppStoreBanner />
      <Testimonial />
      <CarList />
      <Foot />
      <Services />
      <Contact />
      <Part/>
      {/* <Footer /> */}
    </div>
  );
};

export default Home;
